var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flash", on: { mouseup: _vm.closeMouseUp } },
    [
      _c("div", { staticClass: "ui icon message", class: _vm.color }, [
        _vm.icon ? _c("i", { staticClass: "icon", class: _vm.icon }) : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "header" }, [_vm._t("default")], 2),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "close", on: { mouseup: _vm.closeMouseUp } }, [
          _c("i", { staticClass: "icon close" }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "file-browser" },
    [
      _c("ContextMenu", { attrs: { items: _vm.contextMenu } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "search-box" } }, [
        _c("div", { staticClass: "box" }, [
          _c(
            "div",
            {
              style: {
                visibility:
                  _vm.filterRaw != null && _vm.filterRaw.length > 0
                    ? "visible"
                    : "hidden",
              },
              attrs: { id: "cancel" },
              on: {
                click: function ($event) {
                  return _vm.clearSearch()
                },
              },
            },
            [_c("i", { staticClass: "icon cancel" })]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filterRaw,
                expression: "filterRaw",
              },
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.filterRaw },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.search()
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.filterRaw = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            attrs: { id: "src" },
            on: {
              click: function ($event) {
                return _vm.search()
              },
            },
          },
          [_c("i", { staticClass: "icon search" })]
        ),
      ]),
      _vm._v(" "),
      _c("TreeView", {
        attrs: { nodes: _vm.nodes, getChildren: _vm.getChildren },
        on: {
          selectionChanged: _vm.handleSelectionChanged,
          opened: _vm.handleOpen,
        },
      }),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "loading" }, [
            _c("i", { staticClass: "icon circle notch spin" }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
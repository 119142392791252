var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "window-container",
      class: { modal: _vm.modal },
      on: { mouseup: _vm.handleContainerCloseClick },
    },
    [
      _c(
        "div",
        {
          ref: "window",
          staticClass: "window",
          class: {
            dragging: _vm.dragging,
            resizing: _vm.resizing,
            fixed: _vm.fixedPosition,
          },
          style: _vm.winStyle,
          attrs: { id: _vm.id },
          on: { mousedown: _vm.handleZ, mouseup: _vm.handleWindowCloseClick },
        },
        [
          _c("div", { ref: "title", staticClass: "title" }, [
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.title))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "close", on: { mouseup: _vm.closeMouseUp } },
              [_c("i", { staticClass: "icon close" })]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { ref: "content", staticClass: "content" },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Window",
    {
      attrs: {
        title: "Add User",
        id: "addUserDialog",
        modal: "",
        maxWidth: "70%",
        fixedSize: "",
      },
      on: { onClose: _vm.close },
    },
    [
      _vm.loading
        ? _c("div", { staticClass: "loading" }, [
            _c("i", { staticClass: "icon circle notch spin" }),
          ])
        : _c(
            "div",
            { staticClass: "dialog" },
            [
              _c("Message", { attrs: { bindTo: "error" } }),
              _vm._v(" "),
              _vm.success
                ? _c("div", { staticClass: "ui message positive" }, [
                    _c("p", [
                      _c("strong", [_vm._v("User added successfully!")]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "ui form",
                  class: { error: !!_vm.error },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("Username")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.username,
                          expression: "username",
                        },
                      ],
                      ref: "txtUsername",
                      attrs: { type: "text", placeholder: "" },
                      domProps: { value: _vm.username },
                      on: {
                        keydown: function ($event) {
                          return _vm.clearError()
                        },
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.confirmAddUser()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.username = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("Password")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      attrs: { type: "password", placeholder: "" },
                      domProps: { value: _vm.password },
                      on: {
                        keydown: function ($event) {
                          return _vm.clearError()
                        },
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.confirmAddUser()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.password = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("Roles")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.roles,
                            expression: "roles",
                          },
                        ],
                        attrs: { multiple: "true" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.roles = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      _vm._l(_vm.allRoles, function (role) {
                        return _c("option", { domProps: { value: role } }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(role) +
                              "\n                    "
                          ),
                        ])
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("small", [_vm._v(" Hold CTRL/⌘ to deselect roles")]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "ui button",
                    attrs: { disabled: _vm.adding },
                    on: {
                      click: function ($event) {
                        return _vm.close()
                      },
                    },
                  },
                  [_vm._v("\n                Close\n            ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "ui button primary",
                    class: { loading: _vm.adding },
                    attrs: { disabled: _vm.adding || !_vm.isFilled() },
                    on: {
                      click: function ($event) {
                        return _vm.confirmAddUser()
                      },
                    },
                  },
                  [_vm._v("\n                Add User\n            ")]
                ),
              ]),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "potree" } },
    [
      _c("TabViewLoader", {
        attrs: { titleSuffix: "Point Cloud" },
        on: { loaded: _vm.handleLoad },
      }),
      _vm._v(" "),
      _c("Message", { attrs: { bindTo: "error", noDismiss: "" } }),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "loading" }, [
            _c("p", [_vm._v("Loading point cloud...")]),
            _vm._v(" "),
            _c("i", { staticClass: "icon circle notch spin" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "potree-container", class: { loading: _vm.loading } },
        [
          _c("div", {
            ref: "sidebar",
            attrs: { id: "potree_sidebar_container" },
          }),
          _vm._v(" "),
          _c("div", { ref: "container", attrs: { id: "potree_render_area" } }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
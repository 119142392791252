var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "users" } },
    [
      _c("Message", { attrs: { bindTo: "error" } }),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "loading" }, [
            _c("i", { staticClass: "icon circle notch spin" }),
          ])
        : _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "top ui equal width grid middle aligned" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "column right aligned" }, [
                    _c(
                      "button",
                      {
                        staticClass: "ui primary button icon",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.showAddUserDialog = true
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "ui icon add" }),
                        _vm._v(" New User"),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.users, function (u) {
                return _c("div", { staticClass: "ui segments users" }, [
                  _c(
                    "div",
                    {
                      staticClass: "ui segment",
                      on: { click: function ($event) {} },
                    },
                    [
                      _c("div", { staticClass: "ui two column grid" }, [
                        _c("div", { staticClass: "two column row" }, [
                          _c(
                            "div",
                            { staticClass: "thirteen wide column main" },
                            [
                              _c("i", { staticClass: "user icon" }),
                              _vm._v(_vm._s(u.username)),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "three wide column right aligned" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "ui button icon small negative",
                                  class: { loading: u.deleting },
                                  attrs: {
                                    disabled:
                                      u.deleting || u.username === "admin",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleDelete(u)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "ui icon trash" })]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ])
              }),
              _vm._v(" "),
              _vm.users.length == 0
                ? _c("div", { staticClass: "ui segment" }, [
                    _c("h3", [_vm._v("No users found")]),
                    _vm._v(" "),
                    _c("p", [_vm._v("Are you logged in?")]),
                  ])
                : _vm._e(),
            ],
            2
          ),
      _vm._v(" "),
      _vm.showAddUserDialog
        ? _c("AddUserDialog", { on: { onClose: _vm.handleCloseAddUser } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column" }, [_c("h1", [_vm._v("Users")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }
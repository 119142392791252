var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Window",
    {
      attrs: {
        title: _vm.title,
        id: "organizationDialog",
        modal: "",
        maxWidth: "70%",
        fixedSize: "",
      },
      on: {
        onClose: function ($event) {
          return _vm.close("close")
        },
      },
    },
    [
      _c("div", { staticClass: "org-dialog" }, [
        _c(
          "form",
          {
            staticClass: "ui form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("div", { staticClass: "fields" }, [
              _c("div", { staticClass: "field" }, [
                _c("label", [_vm._v("Name")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.org.name,
                      expression: "org.name",
                    },
                  ],
                  ref: "name",
                  attrs: { type: "text", placeholder: "Name" },
                  domProps: { value: _vm.org.name },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      _vm.isValid() &&
                        _vm.close(_vm.mode == "new" ? "create" : "save")
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.org, "name", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "field" }, [
              _c("label", [_vm._v("Description")]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.org.description,
                    expression: "org.description",
                  },
                ],
                attrs: { placeholder: "Description" },
                domProps: { value: _vm.org.description },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.org, "description", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "inline field" }, [
              _c("label", [_vm._v("Public")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.org.isPublic,
                    expression: "org.isPublic",
                  },
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.org.isPublic)
                    ? _vm._i(_vm.org.isPublic, null) > -1
                    : _vm.org.isPublic,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.org.isPublic,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.org, "isPublic", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.org,
                            "isPublic",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.org, "isPublic", $$c)
                    }
                  },
                },
              }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "buttons" }, [
          _c(
            "button",
            {
              staticClass: "ui button",
              on: {
                click: function ($event) {
                  return _vm.close("close")
                },
              },
            },
            [_vm._v("\n                Close\n            ")]
          ),
          _vm._v(" "),
          _vm.mode == "new"
            ? _c(
                "button",
                {
                  staticClass: "ui button primary",
                  attrs: { disabled: !_vm.isValid() },
                  on: {
                    click: function ($event) {
                      return _vm.close("create")
                    },
                  },
                },
                [_vm._v("\n                Create\n            ")]
              )
            : _c(
                "button",
                {
                  staticClass: "ui button primary",
                  attrs: { disabled: !_vm.isValid() },
                  on: {
                    click: function ($event) {
                      return _vm.close("save")
                    },
                  },
                },
                [_vm._v("\n                Save\n            ")]
              ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
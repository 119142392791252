var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.parentRef[_vm.bindTo]
    ? _c("div", { staticClass: "ui message", class: _vm.className }, [
        !_vm.noDismiss
          ? _c("i", { staticClass: "close icon", on: { click: _vm.dismiss } })
          : _vm._e(),
        _vm._v(" "),
        _c("span", {
          domProps: { innerHTML: _vm._s(_vm.parentRef[_vm.bindTo]) },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
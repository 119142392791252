var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ui dropdown item",
      style: [_vm.styleObj, { visibility: _vm.open ? "visible" : "hidden" }],
    },
    [
      _c(
        "div",
        { staticClass: "menu" },
        [
          _vm._l(_vm.visibleItems, function (item) {
            return [
              item.type === "separator"
                ? _c("div", { staticClass: "divider" })
                : _c(
                    "div",
                    {
                      staticClass: "item",
                      class: {
                        disabled:
                          item.isEnabled !== undefined
                            ? !item.isEnabled()
                            : false,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleClick(item)
                        },
                      },
                    },
                    [
                      item.icon != null
                        ? _c("i", {
                            staticClass: "icon",
                            class: item.icon,
                            staticStyle: { "margin-right": "0.5rem" },
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n                " +
                          _vm._s(item.label) +
                          "\n            "
                      ),
                    ]
                  ),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
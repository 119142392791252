var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    Array.isArray(_vm.tObj)
      ? _c(
          "ul",
          _vm._l(_vm.tObj, function (item, index) {
            return _c("li", [_c("ObjTable", { attrs: { obj: item } })], 1)
          }),
          0
        )
      : typeof _vm.tObj === "object"
      ? _c("div", [
          _c(
            "table",
            { staticClass: "ui compact celled definition unstackable table" },
            [
              _c(
                "tbody",
                _vm._l(_vm.tObj, function (val, key) {
                  return _c("tr", { key: key }, [
                    _c("td", { staticStyle: { "white-space": "nowrap" } }, [
                      _vm._v(
                        "\n                        " + _vm._s(_vm.beautify(key))
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        key == "captureTime"
                          ? _c("div", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(new Date(val)) +
                                  "\n                        "
                              ),
                            ])
                          : key == "bands"
                          ? _c("div", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    val.map((b) => b.colorInterp).join(", ")
                                  ) +
                                  "\n                        "
                              ),
                            ])
                          : key == "dimensions"
                          ? _c("div", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(val.join(", ")) +
                                  "\n                        "
                              ),
                            ])
                          : typeof val === "string"
                          ? _c("div", { staticClass: "wrap" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(val) +
                                  "\n                        "
                              ),
                            ])
                          : _c("ObjTable", { attrs: { obj: val } }),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
            ]
          ),
        ])
      : typeof _vm.tObj === "number"
      ? _c("div", [
          _vm._v("\n        " + _vm._s(_vm.obj.toLocaleString()) + "\n    "),
        ])
      : _c("div", [_vm._v("\n        " + _vm._s(_vm.obj) + "\n    ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
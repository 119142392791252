var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Window",
    {
      attrs: {
        title: "Settings",
        id: "settings",
        modal: "",
        maxWidth: "70%",
        fixedSize: "",
        fixedPosition: "",
        closeModalOnClick: "",
      },
      on: {
        onClose: function ($event) {
          return _vm.close("close")
        },
      },
    },
    [
      _c("Message", { attrs: { bindTo: "error" } }),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "loading" }, [
            _c("i", { staticClass: "icon circle notch spin" }),
          ])
        : _c("div", { staticClass: "content" }, [
            _c("h4", { staticClass: "ui header" }, [
              _c("i", {
                staticClass: "icon",
                class: {
                  unlock: _vm.noAuthRequired,
                  lock: !_vm.noAuthRequired,
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v("\n            Visibility: "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.visibility,
                        expression: "visibility",
                      },
                    ],
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.visibility = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.setVisibility,
                      ],
                    },
                  },
                  _vm._l(_vm.Visibilities, function (v, k) {
                    return _c("option", { domProps: { value: k } }, [
                      _vm._v(_vm._s(v.label)),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "description",
              domProps: { innerHTML: _vm._s(_vm.description) },
            }),
            _vm._v(" "),
            _c("h4", { staticClass: "ui header" }, [
              _c("i", { staticClass: "icon balance scale" }),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v("\n            License: "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.license,
                        expression: "license",
                      },
                    ],
                    staticClass: "license-dropdown",
                    staticStyle: { "text-overflow": "ellipsis" },
                    attrs: { title: _vm.Licenses[_vm.license].name },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.license = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.setLicense,
                      ],
                    },
                  },
                  _vm._l(_vm.Licenses, function (v, k) {
                    return _c("option", { domProps: { value: k } }, [
                      _vm._v(_vm._s(v.name)),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.readme == null
              ? _c("div", { staticClass: "extra" }, [
                  _c(
                    "button",
                    {
                      staticClass: "ui button basic icon",
                      on: {
                        click: function ($event) {
                          return _vm.addDocument("README.md")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "icon book" }),
                      _vm._v(" Add Readme\n            "),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
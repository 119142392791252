var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Window",
    {
      attrs: {
        title: "Add to dataset",
        id: "addToDataset",
        modal: "",
        fixedSize: "",
      },
      on: { onClose: _vm.close },
    },
    [
      _c(
        "div",
        { staticStyle: { "min-width": "320px" } },
        [
          _c("DatasetUpload", {
            attrs: {
              organization: _vm.organization,
              dataset: _vm.dataset,
              path: _vm.path,
              open: _vm.open,
              filesToUpload: _vm.filesToUpload,
            },
            on: { onUpload: _vm.onUploaded, done: _vm.done },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./ChangePwdDialog.vue?vue&type=template&id=ebfa2854&scoped=true&"
import script from "./ChangePwdDialog.vue?vue&type=script&lang=js&"
export * from "./ChangePwdDialog.vue?vue&type=script&lang=js&"
import style0 from "./ChangePwdDialog.vue?vue&type=style&index=0&id=ebfa2854&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebfa2854",
  null
  
)

export default component.exports
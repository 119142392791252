var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cui app", attrs: { id: "browser" } },
    [
      _c("Message", { attrs: { bindTo: "error" } }),
      _vm._v(" "),
      _c(
        "Panel",
        {
          staticClass: "container main",
          attrs: {
            split: "vertical",
            amount: "23.6%",
            mobileAmount: "0%",
            tabletAmount: "30%",
            mobileCollapsed: "",
          },
        },
        [
          _c(
            "div",
            { staticClass: "sidebar" },
            [
              _c("FileBrowser", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isMobile,
                    expression: "!isMobile",
                  },
                ],
                attrs: { rootNodes: _vm.rootNodes },
                on: {
                  openItem: _vm.handleOpenItem,
                  selectionChanged: _vm.handleFileSelectionChanged,
                  currentUriChanged: _vm.handleCurrentUriChanged,
                  openProperties: _vm.handleFileBrowserOpenProperties,
                  deleteSelecteditems: _vm.openDeleteItemsDialogFromFileBrowser,
                  moveSelectedItems: _vm.openRenameItemsDialogFromFileBrowser,
                  error: _vm.handleError,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("TabSwitcher", {
            ref: "mainTabSwitcher",
            attrs: {
              tabs: _vm.mainTabs,
              selectedTab: _vm.startTab,
              position: "top",
              buttonWidth: "auto",
              hideSingle: false,
            },
            scopedSlots: _vm._u(
              [
                _vm.isMobile
                  ? {
                      key: "filebrowser",
                      fn: function () {
                        return [
                          _c("FileBrowser", {
                            attrs: { rootNodes: _vm.rootNodes },
                            on: {
                              openItem: _vm.handleOpenItem,
                              selectionChanged: _vm.handleFileSelectionChanged,
                              currentUriChanged: _vm.handleCurrentUriChanged,
                              openProperties:
                                _vm.handleFileBrowserOpenProperties,
                              deleteSelecteditems:
                                _vm.openDeleteItemsDialogFromFileBrowser,
                              moveSelectedItems:
                                _vm.openRenameItemsDialogFromFileBrowser,
                              error: _vm.handleError,
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
                {
                  key: "map",
                  fn: function () {
                    return [
                      _c("Map", {
                        attrs: { lazyload: "", files: _vm.fileBrowserFiles },
                        on: {
                          scrollTo: _vm.handleScrollTo,
                          openItem: _vm.handleOpenItem,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "explorer",
                  fn: function () {
                    return [
                      _c("Explorer", {
                        ref: "explorer",
                        attrs: {
                          files: _vm.fileBrowserFiles,
                          tools: _vm.explorerTools,
                          currentPath: _vm.currentPath,
                        },
                        on: {
                          openItem: _vm.handleOpenItem,
                          createFolder: _vm.handleCreateFolder,
                          deleteSelecteditems: _vm.openDeleteItemsDialog,
                          moveSelectedItems: _vm.openRenameItemsDialog,
                          moveItem: _vm.handleMoveItem,
                          openProperties: _vm.handleExplorerOpenProperties,
                          shareEmbed: _vm.handleShareEmbed,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showProperties
        ? _c("Properties", {
            attrs: { files: _vm.contextMenuFiles },
            on: { onClose: _vm.handleCloseProperties },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSettings
        ? _c("SettingsDialog", {
            attrs: { dataset: _vm.dataset },
            on: {
              onClose: _vm.handleSettingsClose,
              addMarkdown: _vm.handleAddMarkdown,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.uploadDialogOpen
        ? _c("AddToDatasetDialog", {
            attrs: {
              path: _vm.currentPath,
              organization: _vm.dataset.org,
              dataset: _vm.dataset.ds,
              filesToUpload: _vm.filesToUpload,
              open: true,
            },
            on: { onClose: _vm.handleAddClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.deleteDialogOpen
        ? _c("DeleteDialog", {
            attrs: { files: _vm.contextMenuFiles },
            on: { onClose: _vm.handleDeleteClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.renameDialogOpen
        ? _c("RenameDialog", {
            attrs: { file: _vm.fileToRename },
            on: { onClose: _vm.handleRenameClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.createFolderDialogOpen
        ? _c("NewFolderDialog", { on: { onClose: _vm.handleNewFolderClose } })
        : _vm._e(),
      _vm._v(" "),
      _vm.errorDialogOpen
        ? _c(
            "Alert",
            {
              attrs: { title: _vm.errorMessageTitle },
              on: { onClose: _vm.handleErrorDialogClose },
            },
            [_vm._v("\n        " + _vm._s(_vm.errorMessage) + "\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isBusy ? _c("Loader") : _vm._e(),
      _vm._v(" "),
      _vm.flash
        ? _c(
            "Flash",
            {
              attrs: { color: "positive", icon: "check circle outline" },
              on: { onClose: _vm.closeFlash },
            },
            [_vm._v(_vm._s(_vm.flash))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.shareFile
        ? _c("ShareEmbed", {
            attrs: { file: _vm.shareFile },
            on: { onClose: _vm.handleCloseShareEmbed },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
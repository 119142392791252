var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "singleMap" },
    [
      _c("TabViewLoader", {
        attrs: { titleSuffix: "Map" },
        on: { loaded: _vm.loadMap },
      }),
      _vm._v(" "),
      _c("div", { ref: "map-container", staticClass: "map-container" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedBasemap,
                expression: "selectedBasemap",
              },
            ],
            attrs: { id: "basemap-selector" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedBasemap = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.updateBasemap,
              ],
            },
          },
          _vm._l(_vm.basemaps, function (v, k) {
            return _c("option", { domProps: { value: k } }, [
              _vm._v("\n                " + _vm._s(v.label) + "\n            "),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
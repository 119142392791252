var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Window",
    {
      attrs: {
        title: "Confirm delete",
        id: "remove",
        modal: "",
        maxWidth: "70%",
        fixedSize: "",
      },
      on: {
        onClose: function ($event) {
          return _vm.close("close")
        },
      },
    },
    [
      _vm._v(
        "\n\n    Are you sure you want to delete the dataset '" +
          _vm._s(_vm.dsSlug) +
          "'?"
      ),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "ui negative message" }, [
        _c("div", { staticClass: "header" }, [
          _vm._v("\n            This action is irreversible.\n        "),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("All the files will be deleted too.")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "buttons" }, [
        _c(
          "button",
          {
            staticClass: "ui button",
            on: {
              click: function ($event) {
                return _vm.close("close")
              },
            },
          },
          [_vm._v("\n            Close\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "ui button negative",
            on: {
              click: function ($event) {
                return _vm.close("remove")
              },
            },
          },
          [_vm._v("\n            Remove\n        ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Window",
    {
      attrs: { title: "", id: "share-embed", modal: "", fixedSize: "" },
      on: {
        onClose: function ($event) {
          return _vm.$emit("onClose")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("Message", { attrs: { bindTo: "error", noDismiss: "" } }),
          _vm._v(" "),
          _vm.loading
            ? _c("i", { staticClass: "icon circle notch spin" })
            : _c("div", { staticClass: "share-type" }, [
                _c("label", { attrs: { for: "share-mode" } }, [
                  _vm._v("Share: "),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.shareMode,
                        expression: "shareMode",
                      },
                    ],
                    attrs: { id: "share-mode" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.shareMode = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.shareModes, function (v, k) {
                    return _c("option", { domProps: { value: k } }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(v.label) +
                          "\n                "
                      ),
                    ])
                  }),
                  0
                ),
              ]),
          _vm._v(" "),
          _vm.shareMode === "qgis"
            ? _c(
                "div",
                [
                  _vm.url !== ""
                    ? [
                        !_vm.error
                          ? _c(
                              "div",
                              { staticClass: "ui icon positive message" },
                              [
                                _c("i", { staticClass: "cloud upload icon" }),
                                _vm._v(" "),
                                _c("div", { staticClass: "content" }, [
                                  _c("div", { staticClass: "header" }, [
                                    _vm._v(
                                      "\n                        From QGIS\n                        "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm.typeIs("POINTCLOUD")
                                    ? _c("div", [
                                        _vm._v(
                                          "\n                            Layer "
                                        ),
                                        _c("i", {
                                          staticClass: "arrow right icon",
                                        }),
                                        _vm._v(" Add Point Cloud Layer "),
                                        _c("i", {
                                          staticClass: "arrow right icon",
                                        }),
                                        _vm._v(
                                          " Source Type Protocol: HTTP(s) "
                                        ),
                                        _c("i", {
                                          staticClass: "arrow right icon",
                                        }),
                                        _vm._v(
                                          " copy/paste the URL below.\n                        "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.typeIs("GEORASTER")
                                    ? _c("div", [
                                        _vm._v(
                                          "\n                            Layer "
                                        ),
                                        _c("i", {
                                          staticClass: "arrow right icon",
                                        }),
                                        _vm._v(" Add Raster Layer "),
                                        _c("i", {
                                          staticClass: "arrow right icon",
                                        }),
                                        _vm._v(
                                          " Source Type Protocol: HTTP(s) "
                                        ),
                                        _c("i", {
                                          staticClass: "arrow right icon",
                                        }),
                                        _vm._v(
                                          " copy/paste the URL below.\n                        "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.needsAuth
                                    ? _c("div", { staticClass: "auth-note" }, [
                                        _c("i", { staticClass: "lock icon" }),
                                        _vm._v(
                                          " For Authentication choose Basic and use your username and password.\n                        "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "ui action input fluid" }, [
                          _c("input", {
                            attrs: {
                              type: "text",
                              readonly: "",
                              title: "Copy to clipboard",
                            },
                            domProps: { value: _vm.url },
                            on: { click: _vm.copyToClipboard },
                          }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "ui icon button teal",
                              attrs: { title: "Copy to clipboard" },
                              on: { click: _vm.copyToClipboard },
                            },
                            [
                              _c("i", {
                                staticClass: "icon",
                                class: _vm.copyIcon,
                              }),
                            ]
                          ),
                        ]),
                      ]
                    : [
                        _c("div", { staticClass: "ui icon info message" }, [
                          _c("div", { staticClass: "content" }, [
                            _c("div", { staticClass: "header" }, [
                              _vm._v(
                                "\n                        Not supported\n                        "
                              ),
                            ]),
                            _vm._v(
                              "\n                        This file cannot be streamed directly into QGIS (download it instead).\n                    "
                            ),
                          ]),
                        ]),
                      ],
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.shareMode === "link" ||
          _vm.shareMode === "tms" ||
          _vm.shareMode === "embed"
            ? _c("div", [
                _vm.needsAuth
                  ? _c("div", [
                      _c(
                        "a",
                        {
                          staticClass: "ui button primary icon",
                          attrs: { href: "javascript:void(0);" },
                          on: { click: _vm.handleSetUnlisted },
                        },
                        [
                          _c("i", { staticClass: "icon unlock" }),
                          _vm._v(
                            " Allow access to anyone with the link\n                "
                          ),
                        ]
                      ),
                    ])
                  : _c(
                      "div",
                      { staticClass: "ui action input fluid" },
                      [
                        !_vm.url
                          ? _c("div", { staticClass: "ui icon info message" }, [
                              _c("div", { staticClass: "content" }, [
                                _c("div", { staticClass: "header" }, [
                                  _vm._v(
                                    "\n                        Not supported\n                        "
                                  ),
                                ]),
                                _vm._v(
                                  "\n                        This file does not support this type of sharing.\n                    "
                                ),
                              ]),
                            ])
                          : [
                              _vm.shareMode === "embed"
                                ? [
                                    _c("textarea", {
                                      attrs: { readonly: "" },
                                      domProps: { innerHTML: _vm._s(_vm.url) },
                                      on: { click: _vm.copyToClipboard },
                                    }),
                                  ]
                                : [
                                    _c("input", {
                                      attrs: {
                                        type: "text",
                                        readonly: "",
                                        title: "Copy to clipboard",
                                      },
                                      domProps: { value: _vm.url },
                                      on: { click: _vm.copyToClipboard },
                                    }),
                                  ],
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "ui icon button teal",
                                  attrs: { title: "Copy to clipboard" },
                                  on: { click: _vm.copyToClipboard },
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon",
                                    class: _vm.copyIcon,
                                  }),
                                ]
                              ),
                            ],
                      ],
                      2
                    ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
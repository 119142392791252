var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "buttons" },
    [
      _vm._l(_vm.tabs, function (t) {
        return _c(
          "div",
          {
            staticClass: "tab-button",
            class: {
              active: _vm.activeTab === t.key,
              top: _vm.position === "top",
              canClose: !!t.canClose,
            },
            on: {
              click: function ($event) {
                return _vm.setActiveTab(t)
              },
            },
          },
          [
            _c("i", {
              staticClass: "icon",
              class: { padded: !!t.hideLabel, [t.icon]: true },
              attrs: { title: t.label },
            }),
            !t.hideLabel
              ? _c("span", { staticClass: "mobile hide" }, [
                  _vm._v(" " + _vm._s(t.label)),
                ])
              : _vm._e(),
            _vm._v(" "),
            !!t.canClose
              ? _c(
                  "div",
                  {
                    staticClass: "close",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.closeTab(t)
                      },
                    },
                  },
                  [_c("i", { staticClass: "icon close" })]
                )
              : _vm._e(),
          ]
        )
      }),
      _vm._v(" "),
      _vm.buttonWidth === "auto"
        ? _c("div", {
            staticClass: "fill",
            class: {
              top: _vm.position === "top",
              shadowed: _vm.lastTabSelected,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab-view-loader" },
    [
      _c("Message", { attrs: { bindTo: "error", noDismiss: "" } }),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "loading" }, [
            _vm.loadingText
              ? _c("p", [_vm._v(_vm._s(_vm.loadingText))])
              : _vm._e(),
            _vm._v(" "),
            _c("i", { staticClass: "icon circle notch spin" }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
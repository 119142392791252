var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "treeView", staticClass: "tree-view" },
    _vm._l(_vm.nodes, function (node) {
      return _c("TreeNode", {
        key: node.path,
        ref: "treeNodes",
        refInFor: true,
        attrs: { node: node, getChildren: _vm.getChildren },
        on: { selected: _vm.handleSelection, opened: _vm.handleOpen },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tree-node" }, [
    _c(
      "div",
      {
        staticClass: "entry",
        class: { selected: _vm.selected },
        attrs: { draggable: "" },
        on: {
          dragstart: function ($event) {
            return _vm.startDrag($event, _vm.node)
          },
          drop: function ($event) {
            return _vm.onDrop($event, _vm.node)
          },
          dragover: function ($event) {
            $event.preventDefault()
          },
          dragenter: function ($event) {
            $event.preventDefault()
          },
          click: _vm.onClick,
          dblclick: _vm.handleOpenDblClick,
          contextmenu: _vm.onRightClick,
        },
      },
      [
        _vm.loading
          ? _c("i", { staticClass: "icon circle notch spin" })
          : _vm._e(),
        _vm._v(" "),
        _vm.isExpandable && !_vm.loading && (!_vm.empty || !_vm.loadedChildren)
          ? _c("i", {
              staticClass: "icon",
              class: _vm.expanded ? "caret down" : "caret right",
              on: { click: _vm.handleOpenCaret },
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.isExpandable || (_vm.empty && _vm.loadedChildren)
          ? _c("i", { staticClass: "icon nonexistant" })
          : _vm._e(),
        _vm._v(" "),
        _c("i", { staticClass: "icon", class: _vm.node.icon }),
        _vm._v(" "),
        _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.node.label))]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.expanded,
            expression: "expanded",
          },
        ],
        staticClass: "children",
      },
      _vm._l(_vm.children, function (node, index) {
        return _c("TreeNode", {
          key: "N," + node.path,
          ref: "nodes",
          refInFor: true,
          attrs: { node: node, getChildren: _vm.getChildren },
          on: {
            selected: function ($event) {
              return _vm.$emit("selected", $event, arguments[1])
            },
            opened: function ($event) {
              return _vm.$emit("opened", $event, arguments[1])
            },
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
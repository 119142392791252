var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Window",
    {
      attrs: {
        height: "80%",
        width: "40%",
        title: "Properties",
        id: "properties",
      },
      on: {
        onClose: function ($event) {
          return _vm.$emit("onClose", $event, arguments[1])
        },
      },
    },
    [
      _vm.files.length === 1
        ? _c(
            "div",
            [
              _c(
                "table",
                {
                  staticClass: "ui compact celled definition unstackable table",
                },
                [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [_vm._v("Name")]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.files[0].label))]),
                    ]),
                    _vm._v(" "),
                    _vm.files[0].entry.size
                      ? _c("tr", [
                          _c("td", [_vm._v("Size")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.bytesToSize(_vm.files[0].entry.size))
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Type")]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.typeToHuman(_vm.files[0])))]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.files[0].entry.properties != null
                ? _c("ObjTable", {
                    attrs: { obj: _vm.files[0].entry.properties },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.files.length >= 2
        ? _c("div", [
            _c("div", [_vm._v(_vm._s(_vm.files.length) + " items")]),
            _vm._v(" "),
            _c(
              "table",
              {
                staticClass:
                  "ui compact celled definition unstackable table mt",
              },
              [
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v("Size")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.sumSizes(_vm.files)))]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("Type")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.typesToHuman(_vm.files)))]),
                  ]),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { attrs: { id: "notfound" } }, [
      _c("h3", [_vm._v("Ups! Invalid URL.")]),
      _vm._v(" "),
      _c("h5", [
        _vm._v("If you think this is an error, please "),
        _c("a", { attrs: { href: "https://github.com/DroneDB/Hub/issues" } }, [
          _vm._v("report it?"),
        ]),
      ]),
      _vm._v(" "),
      _c("a", { attrs: { href: "/" } }, [_vm._v("Go To Home")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "explorer-container" } },
    [
      _c("ContextMenu", { attrs: { items: _vm.contextMenu } }),
      _vm._v(" "),
      _vm.tools ? _c("Toolbar", { attrs: { tools: _vm.tools } }) : _vm._e(),
      _vm._v(" "),
      _vm.currentPath
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "ui large breadcrumb",
                staticStyle: { "margin-top": "1rem", "margin-left": "1rem" },
              },
              _vm._l(_vm.breadcrumbs, function (b, idx) {
                return _c("span", { key: "B," + b.name }, [
                  idx != _vm.breadcrumbs.length - 1
                    ? _c(
                        "a",
                        {
                          staticClass: "section",
                          class: { home: idx === 0 },
                          on: {
                            click: function ($event) {
                              return _vm.goTo(b)
                            },
                          },
                        },
                        [_vm._v(_vm._s(b.name))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  idx == _vm.breadcrumbs.length - 1
                    ? _c("div", { staticClass: "section active" }, [
                        _vm._v(_vm._s(b.name)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  idx != _vm.breadcrumbs.length - 1
                    ? _c("span", { staticClass: "divider" }, [_vm._v("/")])
                    : _vm._e(),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ui divider" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "explorer",
          class: { loading: _vm.loading, dropping: _vm.dropping },
          attrs: { id: "explorer" },
          on: {
            click: _vm.onClick,
            scroll: _vm.onScroll,
            drop: function ($event) {
              return _vm.explorerDropHandler($event)
            },
            dragleave: function ($event) {
              return _vm.explorerDragLeave($event)
            },
            dragenter: function ($event) {
              return _vm.explorerDragEnter($event)
            },
            dragover: function ($event) {
              $event.preventDefault()
            },
          },
        },
        _vm._l(_vm.files, function (f, idx) {
          return _c(
            "div",
            {
              key: "E," + f.path,
              attrs: { draggable: "" },
              on: {
                dragstart: function ($event) {
                  return _vm.startDrag($event, f)
                },
                drop: function ($event) {
                  return _vm.onDrop($event, f)
                },
                dragenter: function ($event) {
                  $event.preventDefault()
                },
                dragover: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("Thumbnail", {
                ref: "thumbs",
                refInFor: true,
                attrs: { file: f, "data-idx": idx, lazyLoad: true },
                on: { clicked: _vm.handleSelection, open: _vm.handleOpen },
              }),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "thumbnail",
      class: { selected: _vm.file.selected },
      style: { maxWidth: _vm.size + "px" },
      attrs: { title: _vm.error ? _vm.error : _vm.file.path },
      on: {
        click: _vm.onClick,
        contextmenu: _vm.onRightClick,
        dblclick: _vm.onDblClick,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "container",
          class: { bordered: _vm.thumbnail !== null },
          style: _vm.sizeStyle,
        },
        [
          !_vm.icon
            ? _c("img", {
                class: { hide: _vm.thumbnail !== null && _vm.loading },
                attrs: { src: _vm.thumbnail },
                on: { load: _vm.imageLoaded, error: _vm.handleImageError },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.icon && !_vm.loading
            ? _c("i", {
                staticClass: "icon icon-file",
                class: _vm.icon,
                style: _vm.iconStyle,
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loading || (_vm.thumbnail === null && _vm.icon === null)
            ? _c("i", { staticClass: "icon circle notch spin loading" })
            : _vm._e(),
        ]
      ),
      _vm._v("\n    " + _vm._s(_vm.label) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
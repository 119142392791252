var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Window",
    {
      attrs: {
        title: _vm.title,
        id: "datasetDialog",
        modal: "",
        maxWidth: "70%",
        fixedSize: "",
      },
      on: {
        onClose: function ($event) {
          return _vm.close("close")
        },
      },
    },
    [
      _c("div", { staticClass: "ds-dialog" }, [
        _c(
          "form",
          {
            staticClass: "ui form",
            class: { error: !_vm.isValid() },
            on: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _vm.isDuplicateSlug()
              ? _c("div", { staticClass: "ui error message" }, [
                  _c("p", [_vm._v("This name is already in use.")]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "field" }, [
              _c("label", [_vm._v("Name")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ds.name,
                    expression: "ds.name",
                  },
                ],
                ref: "name",
                attrs: { type: "text", placeholder: "Name" },
                domProps: { value: _vm.ds.name },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    _vm.isValid() &&
                      _vm.close(_vm.mode == "new" ? "create" : "save")
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.ds, "name", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "inline field" }, [
              _c("label", [_vm._v("Visibility")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ds.visibility,
                      expression: "ds.visibility",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.ds,
                        "visibility",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: 0 } }, [_vm._v("Private")]),
                  _vm._v(" "),
                  _c("option", { domProps: { value: 1 } }, [
                    _vm._v("Unlisted"),
                  ]),
                  _vm._v(" "),
                  _c("option", { domProps: { value: 2 } }, [_vm._v("Public")]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "buttons" }, [
          _c(
            "button",
            {
              staticClass: "ui button",
              on: {
                click: function ($event) {
                  return _vm.close("close")
                },
              },
            },
            [_vm._v("\n                Close\n            ")]
          ),
          _vm._v(" "),
          _vm.mode == "new"
            ? _c(
                "button",
                {
                  staticClass: "ui button primary",
                  attrs: { disabled: !_vm.isValid() },
                  on: {
                    click: function ($event) {
                      return _vm.close("create")
                    },
                  },
                },
                [_vm._v("\n                Create\n            ")]
              )
            : _c(
                "button",
                {
                  staticClass: "ui button primary",
                  attrs: { disabled: !_vm.isValid() },
                  on: {
                    click: function ($event) {
                      return _vm.close("save")
                    },
                  },
                },
                [_vm._v("\n                Save\n            ")]
              ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "datasets" } },
    [
      _c("Message", { attrs: { bindTo: "error" } }),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "loading" }, [
            _c("i", { staticClass: "icon circle notch spin" }),
          ])
        : _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "top-banner ui equal width grid middle aligned",
                },
                [
                  _c("div", { staticClass: "column" }, [
                    _c("h1", [_vm._v(_vm._s(_vm.orgName))]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "column right aligned" }, [
                    _c(
                      "button",
                      {
                        staticClass: "ui primary button icon",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleNew()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "ui icon add" }),
                        _vm._v(" Create Dataset"),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.datasets, function (ds) {
                return _c("div", { staticClass: "ui segments datasets" }, [
                  _c(
                    "div",
                    {
                      staticClass: "ui segment",
                      on: {
                        click: function ($event) {
                          return _vm.viewDataset(ds)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "ui grid middle aligned flex-container",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex-item column left aligned main-col ds-name",
                            },
                            [
                              _c("i", { staticClass: "database icon" }),
                              _vm._v(_vm._s(ds.name ? ds.name : ds.slug)),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "flex-item column left aligned" },
                            [
                              ds.entries == 0
                                ? _c("span", [_vm._v("0 files")])
                                : _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-bottom": "5px" },
                                      },
                                      [
                                        _vm._v(_vm._s(ds.entries) + " "),
                                        ds.entries > 1
                                          ? _c("span", [_vm._v("files")])
                                          : _c("span", [_vm._v("file")]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(_vm._s(_vm.bytesToSize(ds.size))),
                                    ]),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "flex-item column left aligned" },
                            [
                              ds.visibility === 2
                                ? _c("div", [
                                    _c("i", { staticClass: "unlock icon" }),
                                    _vm._v("Public"),
                                  ])
                                : ds.visibility === 1
                                ? _c("div", [
                                    _c("i", { staticClass: "unlock icon" }),
                                    _vm._v("Unlisted"),
                                  ])
                                : _c("div", [
                                    _c("i", { staticClass: "lock icon" }),
                                    _vm._v("Private"),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex-item column actions right aligned",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "ui button icon small grey",
                                  class: { loading: ds.editing },
                                  attrs: {
                                    disabled: ds.editing || ds.deleting,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleEdit(ds)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "ui icon pencil" })]
                              ),
                              _vm._v(" "),
                              _vm.canDelete
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "ui button icon small negative",
                                      class: { loading: ds.deleting },
                                      attrs: {
                                        disabled: ds.deleting || ds.editing,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleDelete(ds)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "ui icon trash" })]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ])
              }),
              _vm._v(" "),
              _vm.datasets.length == 0
                ? _c("div", { staticClass: "ui segment" }, [_vm._m(0)])
                : _vm._e(),
            ],
            2
          ),
      _vm._v(" "),
      _vm.deleteDialogOpen
        ? _c("DeleteDatasetDialog", {
            attrs: { dsSlug: _vm.currentDsSlug },
            on: { onClose: _vm.handleDeleteClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.messageDialogOpen
        ? _c("MessageDialog", {
            attrs: { message: _vm.currentMessage },
            on: { onClose: _vm.handleMessageClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.dsDialogOpen
        ? _c("DatasetDialog", {
            attrs: {
              mode: _vm.dsDialogMode,
              model: _vm.dsDialogModel,
              forbiddenSlugs: _vm.forbiddenSlugs,
            },
            on: { onClose: _vm.handleDatasetClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ui grid middle aligned" }, [
      _c("div", { staticClass: "column" }, [
        _c("h3", [_vm._v("No datasets found")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "You can create a new dataset by clicking the create dataset button."
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
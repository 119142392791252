var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Window",
    {
      attrs: {
        title: _vm.title,
        id: "alert",
        modal: "",
        maxWidth: "70%",
        fixedSize: "",
      },
      on: {
        onClose: function ($event) {
          return _vm.dismiss("cancel")
        },
      },
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("div", { staticClass: "buttons" }, [
        _vm.buttons.indexOf("ok") !== -1
          ? _c(
              "button",
              {
                staticClass: "ui primary button",
                on: {
                  click: function ($event) {
                    return _vm.dismiss("ok")
                  },
                },
              },
              [_vm._v("\n            OK\n        ")]
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "account" } },
    [
      _c("Message", { attrs: { bindTo: "error" } }),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "loading" }, [
            _c("i", { staticClass: "icon circle notch spin" }),
          ])
        : _c("div", [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "ui segments account" }, [
              _c(
                "div",
                {
                  staticClass: "ui segment",
                  on: { click: _vm.handleChangePwd },
                },
                [
                  _c("div", { staticClass: "ui two column grid" }, [
                    _c("div", { staticClass: "two column row" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "three wide column right aligned" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "ui button icon small primary",
                              class: { loading: _vm.loading },
                              attrs: { disabled: _vm.loading },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleChangePwd.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "ui icon pencil" }),
                              _vm._v(
                                " Change Password\n                            "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
      _vm._v(" "),
      _vm.showChangePwd
        ? _c("ChangePwdDialog", {
            on: {
              onClose: function ($event) {
                _vm.showChangePwd = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "top ui equal width grid middle aligned" },
      [_c("div", { staticClass: "column" }, [_c("h1", [_vm._v("Account")])])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "thirteen wide column main" }, [
      _c("i", { staticClass: "lock icon" }),
      _vm._v("Security"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "upload" } }, [
    _vm.error
      ? _c(
          "div",
          { staticClass: "error-msg" },
          [_c("Message", { attrs: { bindTo: "error" } })],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.uploading
      ? _c("div", { staticClass: "container" }, [
          _vm.uploading
            ? _c("div", { staticClass: "uploading" }, [
                _vm.totalBytes === 0
                  ? _c("div", [
                      _c("i", { staticClass: "icon circle notch spin" }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.totalBytes > 0
                  ? _c(
                      "div",
                      { staticClass: "ui segment" },
                      [
                        Object.keys(_vm.fileUploadStatus).length === 0
                          ? _c("div", [
                              _c("i", {
                                staticClass: "icon circle notch spin",
                              }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(Object.keys(_vm.fileUploadStatus), function (f) {
                          return _c(
                            "div",
                            { staticClass: "progress-indicator" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "ui indicating progress small success",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "bar",
                                      style: {
                                        "min-width":
                                          _vm.fileUploadStatus[f].toFixed(2) +
                                          "%",
                                      },
                                    },
                                    [_c("div", { staticClass: "progress" })]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "label" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.fileUploadStatus[f].toFixed(2)
                                      ) +
                                        "% - " +
                                        _vm._s(f)
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm.totalBytes - _vm.totalBytesSent > 0
                          ? _c("div", { staticClass: "remaining" }, [
                              _c("span", [
                                _vm._v(
                                  "Remaining: " +
                                    _vm._s(_vm.filesCount - _vm.uploadedFiles) +
                                    " files (" +
                                    _vm._s(_vm.humanRemainingBytes) +
                                    ")"
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "ui bottom attached progress" },
                          [
                            _c("div", {
                              staticClass: "bar",
                              style: { "min-width": _vm.totalProgress + "%" },
                            }),
                          ]
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "ui button large negative",
                    on: { click: _vm.handleCancel },
                  },
                  [
                    _c("i", { staticClass: "stop circle outline icon" }),
                    _vm._v(" Cancel\n            "),
                  ]
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.done
      ? _c("div", [
          _vm.error == null
            ? _c("div", { staticClass: "ui icon positive message" }, [
                _c("i", { staticClass: "check circle outline icon" }),
                _vm._v(" "),
                _vm._m(0),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "ui primary submit button",
              on: { click: _vm.resetUpload },
            },
            [_c("i", { staticClass: "redo icon" }), _vm._v(" Upload More")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "droparea",
        staticClass: "droparea",
        class: { hidden: _vm.done || _vm.uploading },
      },
      [
        _c(
          "div",
          {
            ref: "btnUpload",
            staticClass: "ui huge primary submit button",
            on: { click: _vm.handleUpload },
          },
          [_c("i", { staticClass: "cloud upload icon" }), _vm._v(" Browse")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "header" }, [
        _vm._v("\n                Success!\n                "),
      ]),
      _vm._v(
        "\n                Your files have been added to the dataset\n            "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab-switcher" },
    [
      _vm.position === "top" && (!_vm.hideSingle || _vm.dynTabs.length > 1)
        ? _c("TabButtons", {
            ref: "topTabButtons",
            attrs: {
              tabs: _vm.dynTabs,
              defaultTab: _vm.activeTab,
              position: _vm.position,
              buttonWidth: _vm.buttonWidth,
            },
            on: { closeTab: _vm.removeTab, click: _vm.setActiveTab },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tabs" },
        _vm._l(_vm.dynTabs, function (t) {
          return _c(
            "div",
            { staticClass: "tab", class: { hidden: t.key !== _vm.activeTab } },
            [_vm._t(t.key)],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.position === "bottom" && (!_vm.hideSingle || _vm.dynTabs.length > 1)
        ? _c("TabButtons", {
            ref: "bottomTabButtons",
            attrs: {
              tabs: _vm.dynTabs,
              defaultTab: _vm.activeTab,
              position: _vm.position,
              buttonWidth: _vm.buttonWidth,
            },
            on: { closeTab: _vm.removeTab, click: _vm.setActiveTab },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
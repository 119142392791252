var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Window",
    {
      attrs: {
        title: "Information",
        id: "info",
        modal: "",
        maxWidth: "70%",
        fixedSize: "",
      },
      on: {
        onClose: function ($event) {
          return _vm.close("close")
        },
      },
    },
    [
      _vm._v("\n\n    " + _vm._s(_vm.message) + "\n    \n    "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "buttons" }, [
        _c(
          "button",
          {
            staticClass: "ui button",
            on: {
              click: function ($event) {
                return _vm.close("close")
              },
            },
          },
          [_vm._v("\n            Close\n        ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "organizations" } },
    [
      _c("Message", { attrs: { bindTo: "error" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "top-banner ui equal width grid middle aligned" },
        [
          _vm._m(0),
          _vm._v(" "),
          !_vm.readyOnly
            ? _c("div", { staticClass: "column right aligned" }, [
                _c(
                  "button",
                  {
                    staticClass: "ui primary button icon",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleNew()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "ui icon add" }),
                    _vm._v(" Create Organization"),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "loading" }, [
            _c("i", { staticClass: "icon circle notch spin" }),
          ])
        : _c(
            "div",
            [
              _vm._l(_vm.organizations, function (org) {
                return _c("div", { staticClass: "ui segments organization" }, [
                  _c(
                    "div",
                    {
                      staticClass: "ui segment",
                      on: {
                        click: function ($event) {
                          return _vm.viewOrganization(org)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "ui grid middle aligned flex-container",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex-item column left aligned main-col",
                            },
                            [
                              _c("i", { staticClass: "sitemap icon" }),
                              _vm._v(_vm._s(org.name ? org.name : org.slug)),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "flex-item column left aligned" },
                            [
                              org.isPublic
                                ? _c("div", [
                                    _c("i", { staticClass: "unlock icon" }),
                                    _vm._v("Public"),
                                  ])
                                : _c("div", [
                                    _c("i", { staticClass: "lock icon" }),
                                    _vm._v("Private"),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex-item column actions right aligned",
                            },
                            [
                              !_vm.readyOnly &&
                              org.slug !== "public" &&
                              org.slug !== org.owner
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "ui button icon small grey",
                                      class: { loading: org.editing },
                                      attrs: {
                                        disabled: org.editing || org.deleting,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleEdit(org)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "ui icon pencil" })]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.readyOnly &&
                              org.slug !== "public" &&
                              org.slug !== org.owner
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "ui button icon small negative",
                                      class: { loading: org.deleting },
                                      attrs: {
                                        disabled: org.deleting || org.editing,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleDelete(org)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "ui icon trash" })]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ])
              }),
              _vm._v(" "),
              _vm.organizations.length == 0
                ? _c("div", { staticClass: "ui segment" }, [_vm._m(1)])
                : _vm._e(),
            ],
            2
          ),
      _vm._v(" "),
      _vm.deleteDialogOpen
        ? _c("DeleteOrganizationDialog", {
            attrs: { orgSlug: _vm.currentOrgSlug },
            on: { onClose: _vm.handleDeleteClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.messageDialogOpen
        ? _c("MessageDialog", {
            attrs: { message: _vm.currentMessage },
            on: { onClose: _vm.handleMessageClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.orgDialogOpen
        ? _c("OrganizationDialog", {
            attrs: { mode: _vm.orgDialogMode, model: _vm.orgDialogModel },
            on: { onClose: _vm.handleOrganizationClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column" }, [
      _c("h1", [_vm._v("Organizations")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ui grid middle aligned" }, [
      _c("div", { staticClass: "column" }, [
        _c("h3", [_vm._v("No organizations found")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "You can create a new organization by clicking the create organization button."
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "nexus" } },
    [
      _c("TabViewLoader", {
        attrs: { titleSuffix: "Model" },
        on: { loaded: _vm.handleLoad },
      }),
      _vm._v(" "),
      _c("Message", { attrs: { bindTo: "error", noDismiss: "" } }),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "loading" }, [
            _c("p", [_vm._v("Loading model...")]),
            _vm._v(" "),
            _c("i", { staticClass: "icon circle notch spin" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c("canvas", { ref: "canvas" }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Window",
    {
      attrs: {
        title: "Create folder",
        id: "newFolder",
        modal: "",
        maxWidth: "70%",
        fixedSize: "",
      },
      on: {
        onClose: function ($event) {
          return _vm.close("close")
        },
      },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.newFolderPath,
            expression: "newFolderPath",
          },
        ],
        ref: "newFolderInput",
        staticClass: "newFolderInput",
        attrs: { error: !_vm.newFolderPath },
        domProps: { value: _vm.newFolderPath },
        on: {
          keyup: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.createFolder.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.close.apply(null, arguments)
            },
          ],
          input: function ($event) {
            if ($event.target.composing) return
            _vm.newFolderPath = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "buttons" }, [
        _c(
          "button",
          {
            staticClass: "ui button",
            on: {
              click: function ($event) {
                return _vm.close("close")
              },
            },
          },
          [_vm._v("\n            Close\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "ui button positive",
            attrs: { disabled: !_vm.newFolderPath },
            on: { click: _vm.createFolder },
          },
          [_vm._v("\n            Create folder\n        ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
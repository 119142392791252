var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Window",
    {
      attrs: {
        title: "Change Password",
        id: "changePwdDialog",
        modal: "",
        maxWidth: "70%",
        fixedSize: "",
      },
      on: { onClose: _vm.close },
    },
    [
      _c("div", { staticClass: "dialog" }, [
        _c(
          "form",
          {
            staticClass: "ui form",
            class: { error: !!_vm.error },
            on: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _vm.error
              ? _c("div", { staticClass: "ui error message" }, [
                  _c("p", [_vm._v(_vm._s(_vm.error))]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.success
              ? _c("div", { staticClass: "ui message positive" }, [
                  _c("p", [
                    _c("strong", [_vm._v("Password changed successfully!")]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "field" }, [
              _c("label", [_vm._v("Old Password")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.oldPwd,
                    expression: "oldPwd",
                  },
                ],
                ref: "oldPwd",
                attrs: { type: "password", placeholder: "" },
                domProps: { value: _vm.oldPwd },
                on: {
                  keydown: function ($event) {
                    return _vm.clearError()
                  },
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.changePwd()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.oldPwd = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "field" }, [
              _c("label", [_vm._v("New Password")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newPwd,
                    expression: "newPwd",
                  },
                ],
                attrs: { type: "password", placeholder: "" },
                domProps: { value: _vm.newPwd },
                on: {
                  keydown: function ($event) {
                    return _vm.clearError()
                  },
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.changePwd()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.newPwd = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "field" }, [
              _c("label", [_vm._v("Confirm New Password")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.confirmPwd,
                    expression: "confirmPwd",
                  },
                ],
                attrs: { type: "password", placeholder: "" },
                domProps: { value: _vm.confirmPwd },
                on: {
                  keydown: function ($event) {
                    return _vm.clearError()
                  },
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.changePwd()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.confirmPwd = $event.target.value
                  },
                },
              }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "buttons" }, [
          _c(
            "button",
            {
              staticClass: "ui button",
              attrs: { disabled: _vm.changing },
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_vm._v("\n                Close\n            ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "ui button primary",
              class: { loading: _vm.changing },
              attrs: { disabled: _vm.changing || !_vm.isFilled() },
              on: {
                click: function ($event) {
                  return _vm.changePwd()
                },
              },
            },
            [_vm._v("\n                Change Password\n            ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "panorama" } },
    [
      _c("TabViewLoader", {
        attrs: { titleSuffix: "Panorama", loadingText: "Loading viewer..." },
        on: { loaded: _vm.handleLoad },
      }),
      _vm._v(" "),
      _c("Message", { attrs: { bindTo: "error", noDismiss: "" } }),
      _vm._v(" "),
      _c("div", { ref: "viewer", staticClass: "viewer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
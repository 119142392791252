var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "markdown" },
    [
      _c("TabViewLoader", {
        attrs: { titleSuffix: "Markdown" },
        on: { loaded: _vm.handleLoad },
      }),
      _vm._v(" "),
      _c("Message", { attrs: { bindTo: "error" } }),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "loading" }, [
            _c("i", { staticClass: "icon circle notch spin" }),
          ])
        : _c(
            "div",
            { staticClass: "md-container" },
            [
              _vm.editing && _vm.editable
                ? _c("div", { staticClass: "edit-container" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.rawContent,
                          expression: "rawContent",
                        },
                      ],
                      ref: "editTextarea",
                      domProps: { value: _vm.rawContent },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.rawContent = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "ui basic button secondary icon save",
                        on: { click: _vm.save },
                      },
                      [_c("i", { staticClass: "icon save" })]
                    ),
                  ])
                : [
                    _vm.editable
                      ? _c(
                          "button",
                          {
                            staticClass: "ui basic button secondary icon edit",
                            on: { click: _vm.edit },
                          },
                          [_c("i", { staticClass: "icon edit" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "content",
                      domProps: { innerHTML: _vm._s(_vm.content) },
                    }),
                  ],
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }